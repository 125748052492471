@import "reset.css";

html,
body,
#root {
  height: 100%;
}

a {
  text-decoration: none;
}

@font-face {
  font-family: "Myriad Pro";
  src: url('./fonts/MyriadProRegular.woff') format("woff");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Myriad Pro";
  src: url('./fonts/MyriadProSemiBold.woff') format("woff");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "Myriad Pro";
  src: url('./fonts/MyriadProItalic.woff') format("woff");
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: "Myriad Pro";
  src: url('./fonts/MyriadProSemiBoldItalic.woff') format("woff");
  font-weight: 600;
  font-style: italic;
}
